import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, chakra, Spinner } from '@chakra-ui/react';
import { Select, type SingleValue } from 'chakra-react-select';
import { rgba } from 'polished';
import { COLORS, OPACITY } from 'src/constants/colors';
import { useSite } from 'src/context/SiteProvider';
import { checkIfDetailRoutePage, navigateToClosestListPage } from 'src/utils/route';
import { isSite } from 'src/utils/site';
import { CustomOption } from './parts/CustomOption';
import { CustomSingleValue } from './parts/CustomSingleValue';
import { SiteOption } from './types';
import { createSearchSelectOption } from '../SearchSelectInput';

export const SiteSelector = chakra(({ className }) => {
  const { sites, isLoading, setSelectedSiteId, site } = useSite();
  const navigate = useNavigate();
  const routeParams = useParams();
  const [selectedValue, setSelectedValue] = useState<SingleValue<SiteOption>>(
    isSite(site)
      ? createSearchSelectOption({
          label: site.name,
          value: site.id,
        })
      : null,
  );

  useEffect(() => {
    if (isSite(site) && site.id !== selectedValue?.value) {
      setSelectedValue(
        createSearchSelectOption({
          label: site.name,
          value: site.id,
        }),
      );
    }
  }, [site, selectedValue]);

  if (isLoading) {
    return <Spinner />;
  }

  const handleSiteChange = (selectedOption: SingleValue<SiteOption>) => {
    if (selectedOption?.value && selectedValue?.value !== selectedOption.value) {
      setSelectedValue(selectedOption);
      setSelectedSiteId(selectedOption.value);
      localStorage.setItem('siteId', selectedOption.value);
      if (checkIfDetailRoutePage(routeParams)) {
        navigateToClosestListPage(navigate, routeParams);
      }
    }
  };

  const components = {
    Option: CustomOption(sites),
    SingleValue: CustomSingleValue(sites),
  };

  const border = `0.5px solid ${rgba(COLORS.link, OPACITY[32])}`;

  return (
    <Box className={className} data-cy="site-selector">
      <Select
        useBasicStyles
        placeholder="Select existing site"
        onChange={handleSiteChange}
        components={components}
        value={selectedValue}
        options={sites?.map(({ name, id }) => ({
          label: name,
          value: id,
        }))}
        filterOption={(option, inputValue) =>
          option.label.toLocaleLowerCase().includes(inputValue.toLowerCase())
        }
        chakraStyles={{
          control: (provided) => ({
            ...provided,
            border,
            borderRadius: '15px',
            minHeight: 8,
            fontWeight: 300,
            fontSize: '14px',
            _hover: {
              cursor: 'text',
            },
          }),
          option: (provided, { isSelected }) => ({
            ...provided,
            ...(isSelected && {
              backgroundColor: `${COLORS.disabledBlue} !important`,
              color: `${COLORS.white} !important`,
            }),
            fontWeight: 300,
            fontSize: '14px',
          }),
        }}
      />
    </Box>
  );
});
