export const INVALID_EMAIL_ERROR_DESCRIPTIONS = ['Email not verified.'];

export const LOGIN_ERROR_MODAL_TEXTS = {
  NOT_AUTHORIZED: {
    header: 'Not Authorized',
    body: `Hi there, you don’t seem to have access to Observo.
    Please contact us at info@observo.ai to get set up.`,
  },
  INVALID_EMAIL: {
    header: 'Validate Email',
    body: `Check your email inbox for a message from Observo and
    click on the provided link to verify your email address.
    Once the verification process is complete, please log in again to continue.`,
  },
};

export const ERROR_COUNT_POLL_INTERVAL_MS = 2 * 60 * 1000;
export const RESPONSE_ERROR_REASON_PATH = 'response.data.reason';
export const RESPONSE_ERROR_MESSAGE_PATH = 'response.data.message';
export const API_ERROR_MESSAGE_CHAR_LIMIT = 400;
export const RTK_ERROR_EVENT_NAME = 'rtkApiError';
