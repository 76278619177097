import omit from 'lodash/omit';
import { TRANSFORMATIONS_ADDITIONAL_REQUIRED_FIELDS_NAMES } from 'src/constants/transformations';
import { TransformDynamicFormValues } from 'src/types/FormConfig';
import {
  Transform,
  TransformationsItem,
  WellDefinedTransformationsItem,
} from 'src/types/Transform';
import { isNonEmptyArray } from 'src/utils/isNonEmptyArray';

function getFilteredTransformationsItem(
  transformationItems: TransformationsItem[],
): WellDefinedTransformationsItem[] {
  const filteredTransformNodes = transformationItems.filter(
    (item) => item.name !== undefined && item.ruleId !== undefined && item.nodeId !== undefined,
  );
  return filteredTransformNodes as WellDefinedTransformationsItem[];
}

export function getTransformRequestPayload(
  transformationItems: TransformationsItem[],
  pipelineId: string,
  siteId: string,
): Transform[] {
  const filteredTransformNodes = getFilteredTransformationsItem(transformationItems).filter(
    (item) => item.isDirty,
  );

  const transforms = filteredTransformNodes.map(
    ({
      ruleId,
      nodeId,
      name,
      description,
      config,
      isTransformGroup,
      templateName,
      templateVersion,
      siteFilenames,
    }) => ({
      id: nodeId,
      name: name,
      description,
      templateId: ruleId,
      config: omit(
        config,
        TRANSFORMATIONS_ADDITIONAL_REQUIRED_FIELDS_NAMES,
      ) as TransformDynamicFormValues,
      isTransformGroup,
      templateName,
      templateVersion,
      siteFilenames,
      pipelineId,
      siteId,
    }),
  );

  return transforms;
}

export function getOldTransformIdsToNewTransformIdsMap(
  oldTransforms: Transform[],
  newTransforms?: Transform[],
): RecordString {
  const transformOldIdToNewIdMapping: RecordString = {};
  if (isNonEmptyArray(newTransforms)) {
    const oldTransformNameIdsMap: RecordString = {};
    oldTransforms.map(({ id, name }) => {
      oldTransformNameIdsMap[name] = id;
    });
    newTransforms.forEach((transform) => {
      const oldId = oldTransformNameIdsMap[transform.name];
      transformOldIdToNewIdMapping[oldId] = transform.id;
    });
  }
  return transformOldIdToNewIdMapping;
}
