/* eslint-disable */

export const protobufPackage = "errors";

export enum ErrorReason {
  /** EMPTY - EMPTY needed to keep enum compiler rules happy */
  EMPTY = "EMPTY",
  /**
   * NO_DATA - Custom error codes
   * Unassigned ranges available
   * 419-420
   * 432-450
   * 452-499
   * 512-599
   */
  NO_DATA = "NO_DATA",
  SITE_UPDATE_REQUIRED = "SITE_UPDATE_REQUIRED",
  /**
   * MULTIPLE_CHOICES - HTTP error codes
   * https://www.iana.org/assignments/http-status-codes/http-status-codes.xhtml
   */
  MULTIPLE_CHOICES = "MULTIPLE_CHOICES",
  MOVED_PERMANENTLY = "MOVED_PERMANENTLY",
  FOUND = "FOUND",
  SEE_OTHER = "SEE_OTHER",
  NOT_MODIFIED = "NOT_MODIFIED",
  USE_PROXY = "USE_PROXY",
  TEMPORARY_REDIRECT = "TEMPORARY_REDIRECT",
  PERMANENT_REDIRECT = "PERMANENT_REDIRECT",
  BAD_REQUEST = "BAD_REQUEST",
  UNAUTHORIZED = "UNAUTHORIZED",
  PAYMENT_REQUIRED = "PAYMENT_REQUIRED",
  FORBIDDEN = "FORBIDDEN",
  NOT_FOUND = "NOT_FOUND",
  METHOD_NOT_ALLOWED = "METHOD_NOT_ALLOWED",
  NOT_ACCEPTABLE = "NOT_ACCEPTABLE",
  PROXY_AUTHENTICATION_REQUIRED = "PROXY_AUTHENTICATION_REQUIRED",
  REQUEST_TIMEOUT = "REQUEST_TIMEOUT",
  CONFLICT = "CONFLICT",
  GONE = "GONE",
  LENGTH_REQUIRED = "LENGTH_REQUIRED",
  PRECONDITION_FAILED = "PRECONDITION_FAILED",
  PAYLOAD_TOO_LARGE = "PAYLOAD_TOO_LARGE",
  URI_TOO_LONG = "URI_TOO_LONG",
  UNSUPPORTED_MEDIA_TYPE = "UNSUPPORTED_MEDIA_TYPE",
  RANGE_NOT_SATISFIABLE = "RANGE_NOT_SATISFIABLE",
  EXPECTATION_FAILED = "EXPECTATION_FAILED",
  MISDIRECTED_REQUEST = "MISDIRECTED_REQUEST",
  UNPROCESSABLE_ENTITY = "UNPROCESSABLE_ENTITY",
  LOCKED = "LOCKED",
  FAILED_DEPENDENCY = "FAILED_DEPENDENCY",
  UPGRADE_REQUIRED = "UPGRADE_REQUIRED",
  PRECONDITION_REQUIRED = "PRECONDITION_REQUIRED",
  TOO_MANY_REQUESTS = "TOO_MANY_REQUESTS",
  REQUEST_HEADER_FIELDS_TOO_LARGE = "REQUEST_HEADER_FIELDS_TOO_LARGE",
  INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
  NOT_IMPLEMENTED = "NOT_IMPLEMENTED",
  BAD_GATEWAY = "BAD_GATEWAY",
  SERVICE_UNAVAILABLE = "SERVICE_UNAVAILABLE",
  GATEWAY_TIMEOUT = "GATEWAY_TIMEOUT",
  HTTP_VERSION_NOT_SUPPORTED = "HTTP_VERSION_NOT_SUPPORTED",
  VARIANT_ALSO_NEGOTIATES = "VARIANT_ALSO_NEGOTIATES",
  INSUFFICIENT_STORAGE = "INSUFFICIENT_STORAGE",
  LOOP_DETECTED = "LOOP_DETECTED",
  NOT_EXTENDED = "NOT_EXTENDED",
  NETWORK_AUTHENTICATION_REQUIRED = "NETWORK_AUTHENTICATION_REQUIRED",
}
