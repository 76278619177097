import { Pagination } from '@common-types/common/v1/common';
import { Sink, Source } from '@common-types/site_manager/model/v1/node';
import { SerializePipelineResponse } from '@common-types/site_manager/api/v1/site_api';
import apiSlice from 'src/store/api';
import { RTK_QUERY_DEFAULT_STALE_TIME } from 'src/constants/queries';
import { PaginationRequestParams, PaginationSortParams } from 'src/types/PaginationRequest';
import { PullRequestStatusType } from 'src/pages/Events/parts/PullRequests/types';
import { CreateChangeSetRequest } from 'src/hooks/useComponentMutations/types';

export interface PullRequest {
  id: string;
  summary: string;
  createdAt: string;
  status: PullRequestStatusType;
}

interface PullRequestListResponse {
  pullRequests: PullRequest[];
  pagination: Pagination | undefined;
}

// TODO: update from common once added
export interface PullRequestDetailsResponse {
  sources: {
    updated: Source[];
    deleted: Source[];
    created: Source[];
  };
  destinations: {
    updated: Sink[];
    deleted: Sink[];
    created: Sink[];
  };
  pipelines: {
    updated: [];
    deleted: [];
    created: [
      {
        old: SerializePipelineResponse;
        new: SerializePipelineResponse;
        // patch: 'patch';
        components: {
          added: {
            sources: string[];
            destinations: string[];
            transforms: string[];
          };
          removed: {
            sources: string[];
            destinations: string[];
            transforms: string[];
          };
          updated: {
            sources: string[];
            destinations: string[];
            transforms: string[];
          };
        };
      },
    ];
  };
  // patches: 'git patch';
}

interface PullRequestDetailsParams {
  siteId: string;
  pullRequestId: string;
}

interface ClosePullRequestListRequest {
  pullRequestId: string;
}

interface PullRequestListParams {
  siteId: string;
  pagination?: PaginationRequestParams | undefined;
  paginationSort?: PaginationSortParams;
}

const API_TAG_PULL_REQUESTS_LIST = 'pullRequestsList';
const API_TAG_PULL_REQUESTS_DETAILS = 'pullRequestDetails';

const apiWithTag = apiSlice.enhanceEndpoints({
  addTagTypes: [API_TAG_PULL_REQUESTS_LIST, API_TAG_PULL_REQUESTS_DETAILS],
});

const injectedEndpoints = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    pullRequestsList: build.query<PullRequestListResponse, PullRequestListParams>({
      query: ({ siteId, pagination, paginationSort }) => ({
        url: '/v1/gitops/changeset',
        params: {
          siteId,
          ...pagination,
          'pagination.ordering.fieldName': 'updated_at',
          'pagination.ordering.descending': true,
          ...paginationSort,
        },
      }),
      keepUnusedDataFor: RTK_QUERY_DEFAULT_STALE_TIME,
      providesTags: [API_TAG_PULL_REQUESTS_LIST],
    }),

    createPullRequest: build.mutation<unknown, CreateChangeSetRequest>({
      query: (data) => ({
        url: '/v1/gitops/changeset',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [API_TAG_PULL_REQUESTS_LIST],
    }),

    closePullRequest: build.mutation<unknown, ClosePullRequestListRequest>({
      query: ({ pullRequestId }) => ({
        url: `/v1/gitops/changeset${pullRequestId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [API_TAG_PULL_REQUESTS_LIST],
    }),

    getPullRequestDetails: build.query<PullRequestDetailsResponse, PullRequestDetailsParams>({
      query: ({ siteId, pullRequestId }) => ({
        url: '/v1/pull-request-details',
        params: {
          siteId,
          pullRequestId,
        },
      }),
      providesTags: [API_TAG_PULL_REQUESTS_DETAILS],
    }),
  }),
  overrideExisting: false,
});

export const {
  usePullRequestsListQuery,
  useClosePullRequestMutation,
  useCreatePullRequestMutation,
  useGetPullRequestDetailsQuery,
} = injectedEndpoints;
