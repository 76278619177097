export const OPACITY = {
  1: 0.01,
  4: 0.04,
  8: 0.08,
  12: 0.12,
  15: 0.15,
  16: 0.16,
  20: 0.2,
  25: 0.25,
  30: 0.3,
  32: 0.32,
  50: 0.5,
  60: 0.6,
  64: 0.64,
  75: 0.75,
  80: 0.8,
  90: 0.9,
  100: 1,
};

export const COLORS = {
  fullWhite: '#ffffff',
  white: '#F5F5FE',
  black: '#000000',
  input: '#E3E3E3',
  label: '#CFD2D9',
  hint: '#888EA0',
  disabled: '#A0A5B2',
  code: '#0D1133',
  link: '#62B3FF',
  hintText: '#a6a2b2',
  inactiveLink: '#3b669e',
  navbarHover: '#292A5E',
  darkBlue: '#2D2E5A',
  darkerBlue: '#172247',
  lighterBlue: '#318ADC',
  disabledBlue: '#20538E',
  lightVividSkyBlue: '#07d9f5',
  brightBlue: '#0084FF',
  darkPurple: '#302E5E',
  lighterPurple: '#3e3c6d',
  highlightColor: '#3DA2FF',
  transformationGradientLightGreen: '#4dffdf',
  transformationGradientLightBlue: '#4da1ff',
  destinationGradientPurple: '#7d40ff',
  destinationNodeBorder: '#02a4ff',
  cardBackground: '#1d1234',
  menuHover: '#111A3F',
  dialogBackground: '#1E234D',
  popoverBackground: '#07153A',
  popoverFooterText: '#7CBFFD',
  formError: '#fc8181',
  status: {
    success: '#21DDAA',
    warning: '#FFC123',
    error: '#FF4F50',
    chip: '#AF2CFF',
  },
  notificationStatus: {
    info: '#1D2F5F',
    warning: '#2D2C3C',
    success: '#193643',
    error: '#2D2142',
  },
  source: '#a3024a',
  dummyNodeObservoSource: '#e00469',
  sink: '#2e0296',
  dummyNodeObservoSink: '#4602e3',
  buttonRed: '#CA2D2D',
  optimizationGraph: '#4C94FF',
  cpuUtilizationGraph: '#D71D70',
  inactiveWhiteIcon: '#A1A3B1',
  tGroupHeading: '#2f3466',
  hydrationItem: '#171d47',
  // workaround for saas-ui datepicker to show selected day and highlighted time window
  'primary.500': '#318ADC',
  scrollbar: '#293a6b',
  logSeachTableHead: '#101940',
  metricsHover: 'rgba(98 179 255 / 8%)',
  filterIcon: '#A1A3B1',
  tabBackground: '#2F3466',
};
