import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import { SelectedFilterValues } from 'src/components/Filters/types';
import { APP_DEFAULT_FILTER_VALUES } from 'src/constants';
import { DEFAULT_DATE_FILTER_VALUE } from 'src/constants/dateFilter';
import { ROUTE_PATHS } from 'src/constants/routes';
import { useGetTimeFilterValue } from 'src/hooks/useGetTimeFilterValue';
import { TIMEFRAME_RESOLUTION_MAP } from 'src/pages/Monitoring/constants';
import { convertDateToUnixTimeSeconds, getAnalyticsEndDate } from 'src/utils/date';
import { getDefaultDateFilterValues } from 'src/utils/getDefaultFilterValues';

type TimeFilterContextType = {
  timeQuery: {
    startDateUnixTime: number;
    endDateUnixTime: number;
    resolutionInSeconds: number;
  };
  selectedFilterValues: SelectedFilterValues;
  currentDateFilterDisplayName: string | undefined;
  handleFilterChange: (filterName: string, filterValue: string[]) => void;
  canResetTimeFilter: boolean;
  resetTimeFilter: () => void;
  refreshTimeQuery: () => void;
};

const TimeFilterContext = createContext({
  timeQuery: {
    startDateUnixTime: convertDateToUnixTimeSeconds(
      getDefaultDateFilterValues(APP_DEFAULT_FILTER_VALUES),
    ),
    endDateUnixTime: convertDateToUnixTimeSeconds(getAnalyticsEndDate()),
    resolutionInSeconds: TIMEFRAME_RESOLUTION_MAP[DEFAULT_DATE_FILTER_VALUE],
  },
} as TimeFilterContextType);

export const TIME_FILTER_PAGES = [
  ROUTE_PATHS.dashboard,
  ROUTE_PATHS.hydration,
  ROUTE_PATHS.root,
  ROUTE_PATHS.pipelines.index,
  ROUTE_PATHS.pipelines.pipelineDetails,
  ROUTE_PATHS.sources.index,
  ROUTE_PATHS.destinations,
  ROUTE_PATHS.monitoring.analytics,
  ROUTE_PATHS.monitoring.pipelineErrors,
  ROUTE_PATHS.notifications,
  ROUTE_PATHS.events.notifications,
  ROUTE_PATHS.alerts,
];

export function useTimeFilter() {
  return useContext(TimeFilterContext);
}

export const TimeFilterProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const {
    selectedFilterValues,
    handleFilterChange,
    timeQuery,
    currentDateFilterDisplayName,
    canResetTimeFilter,
    resetTimeFilter,
    refreshTimeQuery,
  } = useGetTimeFilterValue();
  const timeQueryContextValue = useMemo(
    () => ({
      selectedFilterValues,
      timeQuery,
      handleFilterChange,
      currentDateFilterDisplayName,
      canResetTimeFilter,
      resetTimeFilter,
      refreshTimeQuery,
    }),
    [
      timeQuery,
      selectedFilterValues,
      currentDateFilterDisplayName,
      handleFilterChange,
      canResetTimeFilter,
      resetTimeFilter,
      refreshTimeQuery,
    ],
  );

  return (
    <TimeFilterContext.Provider value={timeQueryContextValue}>
      {children}
    </TimeFilterContext.Provider>
  );
};
