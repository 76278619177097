import { Site } from '@common-types/site_manager/model/v1/site';
import { useCallback, useState } from 'react';
import { useLazyListSitesQuery } from 'src/store/api/site';
import { PaginationRequestValue } from 'src/types/PaginationRequest';
import { isNonEmptyArray } from 'src/utils/isNonEmptyArray';
import useOnMountEffect from './useOnMountEffect';

const EMPTY_SITES: Site[] = [];

function getPaginationParams(offset: number) {
  return {
    pagination: {
      [PaginationRequestValue.Offset]: offset,
      [PaginationRequestValue.Limit]: 100,
    },
    paginationSort: {
      [PaginationRequestValue.OrderingFieldName]: 'updated_at',
      [PaginationRequestValue.OrderingDescending]: true,
    },
  };
}

export function useAllSitesQuery() {
  const [sites, setSites] = useState(EMPTY_SITES);
  const [error, setError] = useState<unknown>();
  const [isLoading, setIsLoading] = useState(true);
  const [listSitesQuery] = useLazyListSitesQuery();

  const getAllSites = useCallback(async () => {
    let offset = 0;
    let numOfSites = 0;
    const allSites = [];
    do {
      try {
        const response = await listSitesQuery(getPaginationParams(offset)).unwrap();
        numOfSites = response.pagination?.totalCount || 0;
        if (isNonEmptyArray(response.sites)) {
          allSites.push(...response.sites);
        }
        if (numOfSites === allSites.length) {
          setSites([...allSites]);
          setIsLoading(false);
        }
      } catch (apiError) {
        setError(apiError);
        setIsLoading(false);
      }

      offset += 100;
    } while (offset < numOfSites);
  }, [listSitesQuery]);

  useOnMountEffect(() => {
    getAllSites();
  });

  return { sites, isLoading, error, refetchSites: getAllSites };
}
