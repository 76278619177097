/* eslint-disable */

export const protobufPackage = "v1.site_manager";

export enum DynamicLookupJobStatus {
  DYNAMIC_LOOKUP_JOB_STATUS_UNSPECIFIED = "DYNAMIC_LOOKUP_JOB_STATUS_UNSPECIFIED",
  DYNAMIC_LOOKUP_JOB_STATUS_PENDING = "DYNAMIC_LOOKUP_JOB_STATUS_PENDING",
  DYNAMIC_LOOKUP_JOB_RUNNING = "DYNAMIC_LOOKUP_JOB_RUNNING",
  DYNAMIC_LOOKUP_JOB_DELETED = "DYNAMIC_LOOKUP_JOB_DELETED",
}

export enum FileStatus {
  INACTIVE = "INACTIVE",
  UPLOAD_COMPLETE = "UPLOAD_COMPLETE",
  UPLOAD_FAILED = "UPLOAD_FAILED",
  UPLOAD_IN_PROGRESS = "UPLOAD_IN_PROGRESS",
  UPLOAD_PENDING = "UPLOAD_PENDING",
  DELETED = "DELETED",
}

export interface Point {
  latitude: number;
  longitude: number;
}

/** Next ID: 24 */
export interface Site {
  id: string;
  orgId: string;
  name: string;
  description: string;
  status: Site_Status;
  geoLocation: Point | undefined;
  vendor: Site_Vendor;
  /** View only, port is used internally for tunneling. */
  tunnelPort: number;
  /** Vendor specific configuration. */
  vendorConfig: { [key: string]: any } | undefined;
  maxInstances: number;
  minInstances: number;
  createdBy: string;
  updatedBy: string;
  created: string | undefined;
  updated:
    | string
    | undefined;
  /**
   * ca_cert is CA certificate that can be used by clients connecting
   * to push based source
   */
  caCert: string;
  lastAlive:
    | string
    | undefined;
  /**
   * push_source_ingress refers to the ingress endpoint
   * which is used by push based sources with unique subpaths
   */
  pushSourceIngress: string;
  /**
   * push_source_lb refers to the endpoint which is used by
   * push based sources with unique ports
   */
  pushSourceLb: string;
  k8sInternalSvcUrl: string;
  version: string;
  gitProvider: Site_GitProvider;
  gitRepo: string;
  gitRepoPath: string;
  gitBranch: string;
  gitAccessToken: string;
  managedByGit: boolean;
}

export enum Site_Status {
  DEFAULT_STATUS = "DEFAULT_STATUS",
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
  INACTIVE = "INACTIVE",
}

export enum Site_Vendor {
  DEFAULT_TYPE = "DEFAULT_TYPE",
  AWS = "AWS",
  AZURE = "AZURE",
  GCP = "GCP",
  ON_PREMISE = "ON_PREMISE",
  AIR_GAPPED = "AIR_GAPPED",
}

export enum Site_GitProvider {
  GIT_PROVIDER_UNSPECIFIED = "GIT_PROVIDER_UNSPECIFIED",
  GITHUB = "GITHUB",
  GITLAB = "GITLAB",
}

/** Next ID: 16 */
export interface SiteFile {
  id: string;
  /** Required */
  siteId: string;
  /** Required */
  filename: string;
  /** optional */
  description: string;
  /** Required */
  fileType: SiteFile_FileType;
  /** deprecated */
  data: string;
  createdBy: string;
  updatedBy: string;
  created: string | undefined;
  updated:
    | string
    | undefined;
  /** Might be used in future. */
  filepath: string;
  /** For DATA and LOOK_UP_DATA site file type data will be filled in data_chunk */
  dataChunk: Uint8Array;
  fileSizeBytes: string;
  compressionType: SiteFile_CompressionType;
  fileSchema: { [key: string]: any } | undefined;
  dynamicLookupJob:
    | DynamicLookupJob
    | undefined;
  /** Status of the file */
  status: FileStatus;
  /** Checksum of the file. Required */
  checksum: string;
  geoIpInfo: GeoIPLookupTable | undefined;
}

export enum SiteFile_FileType {
  DEFAULT_TYPE = "DEFAULT_TYPE",
  SECRET = "SECRET",
  DATA = "DATA",
  LOOK_UP_DATA = "LOOK_UP_DATA",
  DYNAMIC_LOOK_UP = "DYNAMIC_LOOK_UP",
  /** GEO_IP - MaxmindDB */
  GEO_IP = "GEO_IP",
}

export enum SiteFile_CompressionType {
  NO_COMPRESSION = "NO_COMPRESSION",
  GZIP = "GZIP",
  ZIP = "ZIP",
}

export interface DynamicLookupJob {
  jobId: string;
  status: DynamicLookupJobStatus;
  resource: JobResource | undefined;
}

export interface GeoIPLookupTable {
  locale?: string | undefined;
  storeOnManager: boolean;
}

export interface JobResource {
  cpuRequest?: number | undefined;
  cpuLimit?: number | undefined;
  memoryRequest?: number | undefined;
  memoryLimit?: number | undefined;
}

export interface PreConfiguredComponentsMetadata {
  name: string;
  description: string;
  componentType: string;
  productType: string;
  vendorName: string;
  filePath: string;
}

export interface PreConfiguredComponentsMetadataFilter {
  names: string[];
  componentTypes: string[];
  productTypes: string[];
  vendorNames: string[];
  searchStrings: string[];
}
