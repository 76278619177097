import { ReactElement } from 'react';
import {
  chakra,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
} from '@chakra-ui/react';
import { rgba } from 'polished';
import { COLORS, OPACITY } from 'src/constants/colors';
import { truncateString } from 'src/utils/text/truncateString';

interface TruncatedTextProps {
  children: string;
  className?: string;
  truncateLength: number;
  tooltipContent?: ReactElement;
  postfix?: string;
  maxBodyWidth?: string | number;
  variant?: 'paragraph' | 'label' | 'link';
  showCursorPointer?: boolean;
}

const POPOVER_MAX_HEIGHT = '200px';

export const TruncatedText = chakra(
  ({
    children,
    className,
    truncateLength,
    tooltipContent,
    postfix,
    maxBodyWidth,
    variant,
    showCursorPointer = false,
  }: TruncatedTextProps) => {
    const truncatedText = truncateString(children, truncateLength);
    const isTruncated = children.length > truncateLength;

    return isTruncated || postfix ? (
      <Popover trigger="hover" openDelay={50} closeDelay={50} placement="top">
        <PopoverTrigger>
          <Text
            display="flex"
            className={className}
            cursor={showCursorPointer ? 'pointer' : 'default'}
            wordBreak="break-all"
            variant={variant}
          >
            {truncatedText}&nbsp;{postfix}
          </Text>
        </PopoverTrigger>
        <Portal>
          <PopoverContent p={0} w="fit-content">
            <PopoverArrow color={rgba(COLORS.fullWhite, OPACITY[20])} />
            <PopoverBody
              p={2}
              display="flex"
              justifyContent="center"
              w="fit-content"
              maxWidth={maxBodyWidth}
              maxHeight={POPOVER_MAX_HEIGHT}
              wordBreak="break-word"
              overflowY="auto"
            >
              {tooltipContent || children}
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    ) : (
      <Text className={className} variant={variant}>
        {children}
      </Text>
    );
  },
);
