import { lazy, Suspense } from 'react';

const Events = lazy(() => import(/* webpackPrefetch: true */ 'src/pages/Events'));

const EventsPage = () => (
  <Suspense>
    <Events />
  </Suspense>
);

export default EventsPage;
