import { createBrowserRouter } from 'react-router-dom';
import App from 'src/App';
import ErrorPage from 'src/components/Error/ErrorPage';
import NotFound from 'src/components/NotFound/NotFound';
import { ROUTE_PATHS } from 'src/constants/routes';
import UserReportsPage from 'src/features/UserReports';
import SiteDetailsPage from 'src/pages/SitePage';
import {
  isApiKeysFeatureEnabled,
  isGitOpsFeatureEnabled,
  isLogSearchFeatureEnabled,
  isRbacFeatureEnabled,
  isShowAlertEventsPageEnabled,
  isSsoFeatureEnabled,
  isUserReportsFeatureEnabled,
} from 'src/utils/featureFlags';
import AlertsPage from './Alerts';
import DashboardPage from './Dashboard';
import DestinationsPage from './Destinations';
import EventsPage from './Events';
import HydrationPage from './Hydration';
import LogSearchPage from './LogSearch';
import MonitoringPage from './Monitoring';
import NotificationsPage from './Notifications';
import PipelineDetails from './PipelineDetails';
import PipelinesPage from './Pipelines';
import PipelineCreationPage from './PipelinesCreate';
import Settings from './Settings';
import SourceDetails from './SourceDetails';
import SourcesPage from './Sources';

const rootRoutes = {
  path: ROUTE_PATHS.root,
  element: <App />,
  errorElement: <ErrorPage />,
  children: [
    {
      index: true,
      element: <DashboardPage />,
    },
    {
      path: ROUTE_PATHS.pipelines.pipelineDetails,
      element: <PipelineDetails />,
    },
    {
      path: ROUTE_PATHS.monitoring.index,
      children: [
        {
          index: true,
          element: <MonitoringPage />,
        },
        {
          path: ROUTE_PATHS.monitoring.analytics,
          element: <MonitoringPage />,
        },
        {
          path: ROUTE_PATHS.monitoring.pipelineErrors,
          element: <MonitoringPage />,
        },
      ],
    },
    {
      path: ROUTE_PATHS.pipelines.index,
      children: [
        {
          index: true,
          element: <PipelinesPage />,
        },
        {
          path: ROUTE_PATHS.pipelines.create,
          element: <PipelineCreationPage />,
        },
      ],
    },
    {
      path: ROUTE_PATHS.destinations,
      element: <DestinationsPage />,
    },
    {
      path: ROUTE_PATHS.sources.index,
      children: [
        {
          index: true,
          element: <SourcesPage />,
        },
        {
          path: ROUTE_PATHS.sources.sourceDetails,
          element: <SourceDetails />,
        },
      ],
    },
    {
      path: ROUTE_PATHS.notifications,
      element: <NotificationsPage />,
    },
    {
      path: ROUTE_PATHS.hydration,
      element: <HydrationPage />,
    },
    {
      path: ROUTE_PATHS.settings.index,
      element: <Settings />,
    },
    {
      path: ROUTE_PATHS.settings.sites,
      element: <Settings />,
    },
    {
      path: ROUTE_PATHS.settings.files,
      element: <Settings />,
    },
    {
      path: ROUTE_PATHS.settings.alertManagement,
      element: <Settings />,
    },
    { path: ROUTE_PATHS.settings.channelManagement, element: <Settings /> },
    {
      path: ROUTE_PATHS.settings.siteDetails,
      element: <SiteDetailsPage />,
    },
  ],
};

if (isLogSearchFeatureEnabled()) {
  rootRoutes.children.push({
    path: ROUTE_PATHS.logSearch,
    element: <LogSearchPage />,
  });
}

if (isApiKeysFeatureEnabled()) {
  rootRoutes.children.push({
    path: ROUTE_PATHS.settings.apiKeys,
    element: <Settings />,
  });
}

if (isSsoFeatureEnabled()) {
  rootRoutes.children.push({
    path: ROUTE_PATHS.settings.sso,
    element: <Settings />,
  });
}

if (isRbacFeatureEnabled()) {
  rootRoutes.children.push(
    {
      path: ROUTE_PATHS.settings.roles,
      element: <Settings />,
    },
    {
      path: ROUTE_PATHS.settings.roleDetails,
      element: <Settings />,
    },
    {
      path: ROUTE_PATHS.settings.users,
      element: <Settings />,
    },
  );
}

if (isShowAlertEventsPageEnabled()) {
  rootRoutes.children.push({ path: ROUTE_PATHS.alerts, element: <AlertsPage /> });
}

if (isUserReportsFeatureEnabled()) {
  rootRoutes.children.push({
    path: ROUTE_PATHS.reports,
    element: <UserReportsPage />,
  });
}

if (isGitOpsFeatureEnabled()) {
  rootRoutes.children.push({
    path: ROUTE_PATHS.events.index,
    children: [
      {
        index: true,
        element: <EventsPage />,
      },
      {
        path: ROUTE_PATHS.events.notifications,
        element: <EventsPage />,
      },
      {
        path: ROUTE_PATHS.events.pullRequests,
        element: <EventsPage />,
      },
    ],
  });
}

const routes = [
  rootRoutes,
  {
    path: ROUTE_PATHS.all,
    element: <NotFound />,
  },
];

export const observoRouter = createBrowserRouter(routes);
