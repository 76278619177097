import { DeleteSinkResponse } from '@common-types/site_manager/api/v1/site_api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNotification } from 'src/components/Notification/NotificationProvider';
import { NotificationStatus } from 'src/components/Notification/types';
import axios from 'src/lib/axios/axios';

export function useDeleteSinkByIdMutation() {
  const queryClient = useQueryClient();
  const { showNotification } = useNotification();

  return useMutation<{ data: DeleteSinkResponse }, unknown, string>({
    mutationFn: (sinkId) => axios.delete(`/v1/sink/${sinkId}`),
    onSuccess: async () => {
      showNotification(
        {
          status: NotificationStatus.Success,
          message: 'Destination successfully removed.',
        },
        { shouldTimeout: true },
      );
      await queryClient.invalidateQueries({ queryKey: ['list-destinations'] });
      await queryClient.invalidateQueries({ queryKey: ['sinks'] });
    },
  });
}
