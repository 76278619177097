import { DEFAULT_DATE_FILTER_VALUE } from './dateFilter';
import { FilterName } from './filterName';

export const CODE_BLOCK_MAX_WIDTH = '596px';
export const CONTENT_MIN_WIDTH = '1280px';
export const PAGE_MIN_WIDTH = '1096px';
export const PAGE_LEVEL_NO_DATA_IMAGE_TOP_PADDING = '15vh';

export const LOCAL_STORAGE_FILTER_TIMESTAMP = String(new Date(2024, 3, 4).getTime());

export const APP_DEFAULT_FILTER_VALUES = {
  [FilterName.Date]: [DEFAULT_DATE_FILTER_VALUE],
};

export const DEBOUNCED_SEARCH_DELAY = 120;
export const THROTTLED_SCROLL_DELAY = 50;
