import { extendTheme } from '@chakra-ui/react';
import { COLORS } from 'src/constants/colors';
import * as components from './components';
import { globalStyles } from './theme/globalStyles';
import { themeFonts } from './theme/themeFonts';

const customTheme = {
  colors: {
    ...COLORS,
  },
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  styles: globalStyles,
  fonts: themeFonts,
  components: {
    ...components,
  },
  sizes: {
    '3xl': '45rem',
    '9xl': '101rem',
  },
};

// Due to chakra TS theme generating script
// eslint-disable-next-line @typescript-eslint/naming-convention
export const theme = extendTheme(customTheme);
