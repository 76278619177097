import { useCallback, useRef } from 'react';
import {
  Button,
  Divider,
  Flex,
  Heading,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  HStack,
  Text,
  FormControl,
  FormLabel,
  Textarea,
} from '@chakra-ui/react';
import { rgba } from 'polished';
import { COLORS, OPACITY } from 'src/constants/colors';
import { InfoBlueIcon } from 'src/components/Icons/InfoBlueIcon';
import { ALWAYS_VISIBLE_SCROLLBAR_STYLES } from 'src/constants/scrollbar';
import { useRaisePr } from 'src/hooks/useComponentMutations';

export function RaisePrModal() {
  const { isModalOpen, submitRaisePrModal, closeRaisePrModal } = useRaisePr();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const onSubmit = useCallback(() => {
    if (textAreaRef.current) {
      submitRaisePrModal(textAreaRef.current.value);
    }
  }, [submitRaisePrModal]);

  if (!isModalOpen) {
    return null;
  }

  return (
    <Modal isOpen onClose={closeRaisePrModal} closeOnOverlayClick={false} isCentered>
      <ModalOverlay />
      <ModalContent justifyContent="center" maxW="xl">
        <ModalHeader
          bgColor={rgba(COLORS.link, OPACITY[8])}
          borderBottom={`1px solid ${rgba(COLORS.link, OPACITY[100])}`}
          borderTopRadius="8px"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <HStack>
            <InfoBlueIcon fill="none" />
            <Heading variant="title-4" ps="2">
              Raise PR
            </Heading>
          </HStack>
          <ModalCloseButton justifySelf="flex-end" position="initial" />
        </ModalHeader>
        <Flex direction="column">
          <Flex
            direction="column"
            p={6}
            w="full"
            overflowY="scroll"
            css={ALWAYS_VISIBLE_SCROLLBAR_STYLES}
          >
            <Text variant="paragraph" mb="4">
              Submit your changes by raising a pull request to the connected Git repository.
            </Text>
            <FormControl>
              <FormLabel color="hint" fontSize="13px">
                Comment (Optional)
              </FormLabel>
              <Textarea
                defaultValue=""
                fontSize="14px"
                ref={textAreaRef}
                noOfLines={5}
                placeholder="Add a comment summarizing the changes for this pull request..."
              />
            </FormControl>
          </Flex>
          <Divider />
          <Flex justify="flex-end" gap={4} p={6} boxShadow="dark-lg">
            <Button variant="outline" onClick={closeRaisePrModal}>
              Cancel
            </Button>
            <Button onClick={onSubmit}>Submit</Button>
          </Flex>
        </Flex>
      </ModalContent>
    </Modal>
  );
}
