import { Flex, Spinner } from '@chakra-ui/react';
import { Badge } from 'src/components/Badge/Badge';
import { useSite } from 'src/context/SiteProvider';
import { useUnreadAlertEventsCountQuery } from 'src/store/api/alerts/alertEvents';

export function AlertCount() {
  const { siteId } = useSite();

  const {
    isLoading: isLoadingAlerts,
    isFetching: isFetchingAlerts,
    currentData: numOfUnreadAlerts,
  } = useUnreadAlertEventsCountQuery({ siteId }, { skip: !siteId });

  const shouldShowAlertBadge = numOfUnreadAlerts !== undefined && numOfUnreadAlerts > 0;

  return (
    <Flex justifyContent="end" w="full">
      {isLoadingAlerts || isFetchingAlerts ? (
        <Spinner width={6} height={6} />
      ) : (
        shouldShowAlertBadge && <Badge count={numOfUnreadAlerts} />
      )}
    </Flex>
  );
}
