import { Site_Vendor } from '@common-types/site_manager/model/v1/site';

export enum SiteFormFieldName {
  Name = 'name',
  Description = 'description',
  CloudType = 'vendor',
  GitProvider = 'gitProvider',
  RepositoryUrl = 'gitRepo',
  Branch = 'gitBranch',
  SshKey = 'gitAccessToken',
  Path = 'gitRepoPath',
  ManagedViaGit = 'managedByGit',
}

export enum SiteFormFieldLabel {
  Name = 'Name',
  Description = 'Description (Optional)',
  CloudType = 'Cloud Type',
  GitProvider = 'Git Provider',
  RepositoryUrl = 'Repository URL',
  Branch = 'Branch',
  SshKey = 'SSH Key',
  Path = 'Path',
}

export interface SiteFormValues {
  [SiteFormFieldName.Name]: string;
  [SiteFormFieldName.Description]: string;
  [SiteFormFieldName.CloudType]?: Site_Vendor;
  [SiteFormFieldName.GitProvider]?: string;
  [SiteFormFieldName.RepositoryUrl]?: string;
  [SiteFormFieldName.Branch]?: string;
  [SiteFormFieldName.SshKey]?: string;
  [SiteFormFieldName.Path]?: string;
  [SiteFormFieldName.ManagedViaGit]?: boolean;
}
