import { useCallback } from 'react';
import { useSite } from 'src/context/SiteProvider';
import { AddSourceMutationRequest, Source, UpdateSourceMutationRequest } from 'src/types/Source';
import { Source as SourceType } from '@common-types/site_manager/model/v1/node';
import { useAddSourceMutation } from '../api/useAddSourceMutation';
import { useDeleteSourceByIdMutation } from '../api/useDeleteSourceByIdMutation';
import { useDisableArchivalForSourceMutation } from '../api/useDisableArchivalForSourceMutation';
import { useEnableArchivalForSourceMutation } from '../api/useEnableArchivalForSourceMutation';
import { useUpdateSourceMutation } from '../api/useUpdateSourceMutation';
import { useRaisePr } from './useRaisePr';

export function useSourceMutations() {
  const { siteId } = useSite();
  const { mutateAsync: mutateDeleteSource, isLoading: isDeleteSourceLoading } =
    useDeleteSourceByIdMutation();

  const { mutateAsync: mutateAddSource } = useAddSourceMutation();
  const { mutateAsync: mutateEnableArchivalForSource } = useEnableArchivalForSourceMutation();
  const { mutateAsync: mutateDisableArchivalForSource } = useDisableArchivalForSourceMutation();
  const { mutateAsync: mutateUpdateSource } = useUpdateSourceMutation();
  const { handleComponentMutations } = useRaisePr();

  const deleteSourceMutation = useCallback(
    async (source: SourceType) =>
      await handleComponentMutations(async () => await mutateDeleteSource(source.id), {
        nodes: { [source.name]: { source: source as Source } },
      }),
    [mutateDeleteSource, handleComponentMutations],
  );

  const updateSourceMutation = useCallback(
    async (request: UpdateSourceMutationRequest, oldName: string) =>
      await handleComponentMutations(async () => await mutateUpdateSource(request), {
        nodes: { [oldName]: { source: request.source } },
      }),
    [mutateUpdateSource, handleComponentMutations],
  );

  const addSourceMutation = useCallback(
    async (request: AddSourceMutationRequest) =>
      await handleComponentMutations(async () => await mutateAddSource(request), {
        nodes: { [request.source.name]: { source: request.source } },
      }),
    [mutateAddSource, handleComponentMutations],
  );

  const mutateArchivalForSource = useCallback(
    async (sourceId: string, sinkId?: string) => {
      if (sinkId) {
        return await mutateEnableArchivalForSource({ siteId, sourceId, sinkId });
      } else {
        return await mutateDisableArchivalForSource({ siteId, sourceId });
      }
    },
    [mutateDisableArchivalForSource, mutateEnableArchivalForSource, siteId],
  );

  const isDeleteSourceMutating = isDeleteSourceLoading;

  return {
    deleteSourceMutation,
    isDeleteSourceMutating,
    updateSourceMutation,
    addSourceMutation,
    mutateArchivalForSource,
  };
}
