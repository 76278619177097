import { Flex } from '@chakra-ui/react';
import { Site } from '@common-types/site_manager/model/v1/site';
import { chakraComponents, SingleValueProps } from 'chakra-react-select';
import { SiteOption } from '../types';
import { getSiteStatusIcon } from '../utils';

export const CustomSingleValue = (sites?: Site[]) => {
  const option = (props: SingleValueProps<SiteOption, false>) => {
    const { data, children } = props;

    const optionSite = sites?.find((site) => site.id === data.value);
    return (
      <chakraComponents.SingleValue {...props}>
        <Flex gap={5} alignItems="center">
          {getSiteStatusIcon(optionSite?.status)}
          {children}
        </Flex>
      </chakraComponents.SingleValue>
    );
  };

  return option;
};
