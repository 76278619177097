import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { Site, Site_Status } from '@common-types/site_manager/model/v1/site';
import { ErrorReason } from '@common-types/common/errors/error_reasons';
import get from 'lodash/get';
import { AppLoadingSpinner } from 'src/components/AppLoadingSpinner/AppLoadingSpinner';
import ErrorPage from 'src/components/Error/ErrorPage';
import { useAllSitesQuery } from 'src/hooks/useAllSitesQuery';
import { isSiteActiveAndAlive } from 'src/utils/site';
import { DispatchStateAction } from 'src/types';
import UnauthorizedPage from 'src/components/Error/UnauthorizedPage';
import { RESPONSE_ERROR_REASON_PATH } from 'src/constants/errors';
import { isNonEmptyArray } from 'src/utils/isNonEmptyArray';

interface SiteProviderContextType {
  siteId: string;
  isLoading: boolean;
  site?: Site;
  sites?: Site[];
  siteIds: string[];
  isSiteActive: boolean;
  refetchSites: () => Promise<void>;
  setSelectedSiteId: DispatchStateAction<string | undefined>;
}

const SiteContext = createContext({} as SiteProviderContextType);

export const useSite = () => useContext(SiteContext);

export const SiteProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [selectedSiteId, setSelectedSiteId] = useState<string | undefined>(
    localStorage.getItem('siteId') || undefined,
  );
  const { isLoading, refetchSites, error, sites } = useAllSitesQuery();

  useEffect(() => {
    if (
      isNonEmptyArray(sites) &&
      (!selectedSiteId || !sites.find(({ id }) => id === selectedSiteId))
    ) {
      const defaultSite = sites.find(isSiteActiveAndAlive) || sites[0];
      setSelectedSiteId(defaultSite.id);
      localStorage.setItem('siteId', defaultSite.id);
    }
  }, [sites, selectedSiteId]);

  const site = useMemo(
    () => sites.find(({ id }) => id === selectedSiteId),
    [selectedSiteId, sites],
  );
  const siteId = site?.id || '';
  const siteIds = useMemo(() => (siteId ? [siteId] : []), [siteId]);
  const isSiteActive = site?.status === Site_Status.ACTIVE;

  const contextValue = useMemo<SiteProviderContextType>(
    () => ({
      sites,
      site,
      isLoading,
      siteId,
      isSiteActive,
      setSelectedSiteId,
      refetchSites,
      siteIds,
    }),
    [isLoading, sites, siteId, site, setSelectedSiteId, refetchSites, isSiteActive, siteIds],
  );

  if (error) {
    const errorReason = get(error, RESPONSE_ERROR_REASON_PATH);
    if (errorReason === ErrorReason.FORBIDDEN) {
      return <UnauthorizedPage error={ErrorReason.FORBIDDEN} />;
    }

    return <ErrorPage />;
  }

  if (isLoading) {
    return <AppLoadingSpinner />;
  }

  return <SiteContext.Provider value={contextValue}>{children}</SiteContext.Provider>;
};
