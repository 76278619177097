import { useMutation, useQueryClient } from '@tanstack/react-query';
import omit from 'lodash/omit';
import axios from 'src/lib/axios/axios';
import { Transform } from 'src/types/Transform';

export const useBulkCreateTransformsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<{ data: { transform: Transform[] } }, unknown, Transform[]>({
    mutationFn: (data) =>
      axios.post('/v1/transforms', { transforms: data.map((transform) => omit(transform, 'id')) }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['transforms'] });
    },
  });
};
