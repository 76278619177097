import { AddPipelineResponse } from '@common-types/pipeline_manager/api/v1/pipeline_api';
import { PipelineType } from '@common-types/pipeline_manager/model/v1/pipeline';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'src/lib/axios/axios';
import { AddPipelineMutationRequest } from 'src/types/Pipeline';

export const useAddPipelineMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<{ data: AddPipelineResponse }, unknown, AddPipelineMutationRequest>({
    mutationFn: (data) => {
      const adjustedData = {
        pipeline: {
          ...data.pipeline,
          pipelineType: PipelineType.PIPELINE_TYPE_USER,
        },
      };

      return axios.post('/v1/pipeline', adjustedData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['pipeline-names'] });
    },
  });
};
