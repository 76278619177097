import { UserNotification_Severity } from '@common-types/notification_service/model/v1/user_notification';

export const MAX_NOTIFICATIONS_COUNT = 6;

export enum NotificationFilterName {
  Severity = 'severity',
  Type = 'type',
}

export const NOTIFICATION_FILTER_SEVERITIES = [
  UserNotification_Severity.INFORMATIONAL,
  UserNotification_Severity.WARNING,
  UserNotification_Severity.ERROR,
];

export const DEFAULT_FILTER_VALUES = {
  [NotificationFilterName.Severity]: [],
  [NotificationFilterName.Type]: [],
};

export enum NotificationType {
  Read = 'read',
  Unread = 'unread',
}

export const NOTIFICATION_FILTER_TYPES = [NotificationType.Read, NotificationType.Unread];

export const NOTIFICATION_TYPE_MAP = {
  [NotificationType.Read]: true,
  [NotificationType.Unread]: false,
};

export const NOTIFICATION_TIMEOUT_MS = 5000;

export const NOTIFICATION_FILTER_SUMMARY_KEY_MAP = {
  [UserNotification_Severity.INFORMATIONAL]: 'informationalSummary',
  [UserNotification_Severity.WARNING]: 'warningSummary',
  [UserNotification_Severity.ERROR]: 'errorSummary',
};

export const NOTIFICATION_FILTER_TYPE_MAP: RecordString = {
  [NotificationType.Read]: 'readCount',
  [NotificationType.Unread]: 'unreadCount',
};

export const EMPTY_TYPE_FILTER_COUNTS = Object.fromEntries(
  NOTIFICATION_FILTER_TYPES.map((key) => [key, 0]),
);

export const EMPTY_SEVERITY_FILTER_COUNTS = Object.fromEntries(
  NOTIFICATION_FILTER_SEVERITIES.map((key) => [key, 0]),
);
