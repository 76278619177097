import { useCallback, useState } from 'react';
import { SingleValue } from 'chakra-react-select';
import { SearchSelectItem } from '../types';

export const useSearchBox = () => {
  const [selectedOption, setSelectedOption] = useState<SingleValue<SearchSelectItem> | null>(null);

  const handleSelect = useCallback((option: SingleValue<SearchSelectItem>) => {
    setSelectedOption(option);
  }, []);

  return {
    selectedOption,
    handleSelect,
  };
};
