/* eslint-disable */

export const protobufPackage = "v1.event";

/** Next ID: 7 */
export interface UserNotification {
  /** title that the user sees in notifications page (Source */
  title: string;
  /** data is hydrated to Destination) */
  header: string;
  /** collapsed list view (Download Ready) */
  description: string;
  /** resource type that the notification is relevant to */
  objectType: string;
  /** (pipeline, source, sink, etc) */
  objectId: string;
  /** (pipelineId, sinkId, etc) */
  severity: UserNotification_Severity;
}

/** Next ID: 3 */
export enum UserNotification_Severity {
  UNKNOWN_SEVERITY = "UNKNOWN_SEVERITY",
  INFORMATIONAL = "INFORMATIONAL",
  WARNING = "WARNING",
  ERROR = "ERROR",
}
