import { cloneElement, Fragment, ReactElement } from 'react';

export const repeat = (element: ReactElement, times: number) =>
  Array.from({ length: times }, (_, index) => (
    <Fragment key={index}>{cloneElement(element, { index })}</Fragment>
  ));

export const isDevEnvironment = () =>
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const isAbsoluteUrl = (url: string) => url.startsWith('http');

export const getURLOrigins = (url?: string, isAddWebSockets = false) => {
  if (!url || !isAbsoluteUrl(url)) {
    return undefined;
  }

  const { origin } = new URL(url);
  const origins = [origin];
  if (isAddWebSockets) {
    origins.push(origin.replace('http', 'ws'));
  }
  return origins.join(' ');
};
