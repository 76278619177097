import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';
import { ComponentDataProcessed } from '@common-types/analytics_service/api/v1/analytics_service';
import { ArchivalInfo, Sink, Source } from '@common-types/site_manager/model/v1/node';
import { DispatchStateAction } from 'src/types';
import { TransformationsItem } from 'src/types/Transform';
import { PipelineGraphMap } from 'src/types/Pipeline';

export interface PipelineComponents {
  source?: Source;
  destination?: Sink;
  destinations?: Sink[];
  archivalInfo?: ArchivalInfo;
  transformationItems: TransformationsItem[];
  pipelineDisplayedGraphVersion: number;
  pipelineComponentDataProcessed?: { [key: string]: ComponentDataProcessed };
}

export interface PipelineGraphContextType {
  isPipelineEditMode: boolean;
  isSourceSinkEditReplaceAllowed: boolean;
  setIsSourceSinkEditReplaceAllowed: DispatchStateAction<boolean>;
  setIsPipelineEditMode: DispatchStateAction<boolean>;
  pipelineName: string;
  setPipelineName: DispatchStateAction<string>;
  isPipelineNameValid: boolean;
  setIsPipelineNameValid: DispatchStateAction<boolean>;
  pipelineDescription: string;
  setPipelineDescription: DispatchStateAction<string>;
  pipelineComponents: PipelineComponents;
  setPipelineComponents: DispatchStateAction<PipelineComponents>;
  isValidPipelineGraph: boolean;
  setIsValidPipelineGraph: DispatchStateAction<boolean>;
  pipelineGraphMap: PipelineGraphMap;
  setPipelineGraphMap: DispatchStateAction<PipelineGraphMap>;
  pipelineGraphValidationError: string;
  setPipelineGraphValidationError: DispatchStateAction<string>;
  isPipelineGraphDirty: boolean;
  setIsPipelineGraphDirty: DispatchStateAction<boolean>;
  pipelineGraphInternalNodes: RecordString;
  setPipelineGraphInternalNodes: DispatchStateAction<RecordString>;
  sourceNodeIds: string[];
  setSourceNodeIds: DispatchStateAction<string[]>;
  sinkNodeIds: string[];
  setSinkNodeIds: DispatchStateAction<string[]>;
}

interface AdditionalPipelineGraphContextType {
  isGraphPointerEventsAllowed: boolean;
  setIsGraphPointerEventsAllowed: DispatchStateAction<boolean>;
  isShowPipelineGraphModals: boolean;
  setIsShowPipelineGraphModals: DispatchStateAction<boolean>;
  validConnectionMap: Record<string, boolean>;
  setValidConnectionMap: DispatchStateAction<Record<string, boolean>>;
}

const PipelineGraphContext = createContext(
  {} as PipelineGraphContextType & AdditionalPipelineGraphContextType,
);

export const usePipelineGraphContext = () => useContext(PipelineGraphContext);

export const DEFAULT_PIPELINE_COMPONENTS: PipelineComponents = {
  transformationItems: [],
  pipelineDisplayedGraphVersion: 0,
};

export const PipelineGraphProvider = ({ children }: PropsWithChildren) => {
  const [isPipelineEditMode, setIsPipelineEditMode] = useState(false);
  const [isSourceSinkEditReplaceAllowed, setIsSourceSinkEditReplaceAllowed] = useState(true);
  const [pipelineName, setPipelineName] = useState('');
  const [pipelineDescription, setPipelineDescription] = useState('');
  const [pipelineComponents, setPipelineComponents] = useState<PipelineComponents>(
    DEFAULT_PIPELINE_COMPONENTS,
  );
  const [isGraphPointerEventsAllowed, setIsGraphPointerEventsAllowed] = useState(true);
  const [isShowPipelineGraphModals, setIsShowPipelineGraphModals] = useState(true);
  const [validConnectionMap, setValidConnectionMap] = useState({});
  const [sourceNodeIds, setSourceNodeIds] = useState([] as string[]);
  const [sinkNodeIds, setSinkNodeIds] = useState([] as string[]);
  const [isValidPipelineGraph, setIsValidPipelineGraph] = useState(false);
  const [isPipelineGraphDirty, setIsPipelineGraphDirty] = useState(false);
  const [pipelineGraphValidationError, setPipelineGraphValidationError] = useState('');
  const [pipelineGraphMap, setPipelineGraphMap] = useState<PipelineGraphMap>({});
  const [pipelineGraphInternalNodes, setPipelineGraphInternalNodes] = useState<RecordString>({});
  const [isPipelineNameValid, setIsPipelineNameValid] = useState(true);

  const contextValue = useMemo(
    () => ({
      isPipelineEditMode,
      setIsPipelineEditMode,
      isSourceSinkEditReplaceAllowed,
      setIsSourceSinkEditReplaceAllowed,
      pipelineName,
      setPipelineName,
      pipelineDescription,
      setPipelineDescription,
      pipelineComponents,
      setPipelineComponents,
      isGraphPointerEventsAllowed,
      setIsGraphPointerEventsAllowed,
      isShowPipelineGraphModals,
      setIsShowPipelineGraphModals,
      validConnectionMap,
      setValidConnectionMap,
      isValidPipelineGraph,
      setIsValidPipelineGraph,
      pipelineGraphMap,
      setPipelineGraphMap,
      pipelineGraphValidationError,
      setPipelineGraphValidationError,
      isPipelineGraphDirty,
      setIsPipelineGraphDirty,
      pipelineGraphInternalNodes,
      setPipelineGraphInternalNodes,
      sourceNodeIds,
      setSourceNodeIds,
      sinkNodeIds,
      setSinkNodeIds,
      isPipelineNameValid,
      setIsPipelineNameValid,
    }),
    [
      isPipelineEditMode,
      isSourceSinkEditReplaceAllowed,
      pipelineName,
      pipelineDescription,
      pipelineComponents,
      isShowPipelineGraphModals,
      isValidPipelineGraph,
      isGraphPointerEventsAllowed,
      validConnectionMap,
      pipelineGraphMap,
      pipelineGraphValidationError,
      isPipelineGraphDirty,
      pipelineGraphInternalNodes,
      sourceNodeIds,
      sinkNodeIds,
      isPipelineNameValid,
    ],
  );

  return (
    <PipelineGraphContext.Provider value={contextValue}>{children}</PipelineGraphContext.Provider>
  );
};
