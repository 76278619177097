import { SiteFormFieldLabel, SiteFormFieldName } from 'src/features/SiteForm/types';
import { ConfigFormat } from 'src/types/FormConfig';

export const CLOUD_TYPE_OPTIONS = [
  {
    label: 'AWS',
    name: 'AWS',
  },
  {
    label: 'GCP',
    name: 'GCP',
  },
  {
    label: 'Azure',
    name: 'AZURE',
  },
  {
    label: 'Air Gapped',
    name: 'AIR_GAPPED',
  },
  {
    label: 'Self Managed',
    name: 'ON_PREMISE',
  },
];

export const SITE_FORM_CONFIG_FORMAT: ConfigFormat = {
  properties: [
    {
      label: SiteFormFieldLabel.Name,
      name: SiteFormFieldName.Name,
      type: 'string',
      placeholder: 'Add Name',
      minLength: 3,
    },
    {
      label: SiteFormFieldLabel.Description,
      name: SiteFormFieldName.Description,
      type: 'string',
      placeholder: 'Add Description',
    },
    {
      label: SiteFormFieldLabel.CloudType,
      name: SiteFormFieldName.CloudType,
      type: 'select',
      placeholder: 'Select Cloud Type',
      options: CLOUD_TYPE_OPTIONS,
    },
  ],
  required: [SiteFormFieldName.Name, SiteFormFieldName.CloudType],
};

export const GIT_FORM_CONFIG_FORMAT: ConfigFormat = {
  properties: [
    {
      label: SiteFormFieldLabel.GitProvider,
      name: SiteFormFieldName.GitProvider,
      type: 'select',
      placeholder: SiteFormFieldLabel.GitProvider,
      options: [
        { name: 'GITHUB', label: 'Github' },
        { name: 'GITLAB', label: 'Gitlab' },
      ],
    },
    {
      label: SiteFormFieldLabel.RepositoryUrl,
      name: SiteFormFieldName.RepositoryUrl,
      type: 'string',
      placeholder: SiteFormFieldLabel.RepositoryUrl,
      minLength: 3,
    },
    {
      label: SiteFormFieldLabel.Branch,
      name: SiteFormFieldName.Branch,
      type: 'string',
      placeholder: SiteFormFieldLabel.Branch,
    },
    {
      label: SiteFormFieldLabel.SshKey,
      name: SiteFormFieldName.SshKey,
      type: 'textarea',
      noOfRows: 5,
      placeholder:
        "Begins with 'ssh-rsa', 'ecdsa-sha2-nistp256', 'ecdsa-sha2-nistp384', 'ecdsa-sha2-nistp521', 'ssh-ed25519', 'sk-ecdsa-sha2-nistp256@openssh.com', or 'sk-ssh-ed25519@openssh.com'",
    },
    {
      label: SiteFormFieldLabel.Path,
      name: SiteFormFieldName.Path,
      type: 'string',
      placeholder: 'Directory path',
    },
  ],
  required: [
    SiteFormFieldName.GitProvider,
    SiteFormFieldName.RepositoryUrl,
    SiteFormFieldName.Branch,
    SiteFormFieldName.SshKey,
  ],
};

export const SITE_DETAILS_CONFIG_FORMAT: ConfigFormat = {
  properties: SITE_FORM_CONFIG_FORMAT.properties,
  required: [],
};

export const GIT_DETAILS_CONFIG_FORMAT: ConfigFormat = {
  properties: GIT_FORM_CONFIG_FORMAT.properties.filter(
    ({ name }) => name !== SiteFormFieldName.SshKey,
  ),
  required: [],
};

export const SITE_POLLING_INTERVAL = 3000;
export const SITE_NEVER_USED = '0001-01-01T00:00:00Z';
