import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSite } from 'src/context/SiteProvider';
import raisePrSlice from 'src/store/raisePr';
import { CreateChangeSetRequest } from './types';
import { useCreatePullRequestMutation } from 'src/store/api/gitOps/pullRequests';
import { isString } from 'src/utils/dataTypeDetection';

const { setRaisePr } = raisePrSlice.actions;
const { selectPrModalShow, selectResolveFn } = raisePrSlice.selectors;

export function useRaisePr() {
  const { site, siteId } = useSite();
  const dispatch = useDispatch();
  const isModalOpen = useSelector(selectPrModalShow);
  const resolveFnHandler = useSelector(selectResolveFn);

  const [createPullRequest] = useCreatePullRequestMutation();

  const closeRaisePrModal = useCallback(async () => {
    await resolveFnHandler();
    dispatch(setRaisePr({ showModal: false }));
  }, [dispatch, resolveFnHandler]);

  const handleComponentMutations = useCallback(
    async <T>(
      mutationFnClosure: () => Promise<T>,
      createRequest: Omit<CreateChangeSetRequest, 'siteId' | 'description'>,
    ): Promise<T | null> => {
      if (site?.managedByGit) {
        return new Promise<null>((resolve, reject) => {
          const resolveFn = async (description?: string) => {
            if (!isString(description)) {
              return reject(null);
            }

            try {
              await createPullRequest({ siteId, description, ...createRequest }).unwrap();
              resolve(null);
            } catch (error) {
              reject(error);
            }
          };
          dispatch(setRaisePr({ showModal: true, resolveFn }));
        });
      } else {
        return await mutationFnClosure();
      }
    },
    [site, dispatch, createPullRequest, siteId],
  );

  const submitRaisePrModal = useCallback(
    async (description: string) => {
      dispatch(setRaisePr({ showModal: false }));
      await resolveFnHandler(description);
    },
    [dispatch, resolveFnHandler],
  );

  return {
    isModalOpen,
    handleComponentMutations,
    closeRaisePrModal,
    submitRaisePrModal,
  };
}
