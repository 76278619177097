import { useCallback } from 'react';
import { useDeleteSinkByIdMutation } from 'src/hooks/api/useDeleteSinkByIdMutation';
import { Sink } from '@common-types/site_manager/model/v1/node';
import { AddSinkMutationRequest, UpdateSinkMutationRequest } from 'src/types/Destination';
import { useAddSinkMutation } from '../api/useAddSinkMutation';
import { useUpdateSinkMutation } from '../api/useUpdateSinkMutation';
import { useRaisePr } from './useRaisePr';

export function useDestinationMutations() {
  const { mutateAsync: mutateDeleteSink, isLoading: isDeleteSinkLoading } =
    useDeleteSinkByIdMutation();
  const { mutateAsync: mutateUpdateSink } = useUpdateSinkMutation();
  const { mutateAsync: mutateAddSink } = useAddSinkMutation();
  const { handleComponentMutations } = useRaisePr();

  const deleteSinkMutation = useCallback(
    async (sink: Sink) =>
      await handleComponentMutations(async () => await mutateDeleteSink(sink.id), {
        nodes: { [sink.name]: { sink } },
      }),
    [handleComponentMutations, mutateDeleteSink],
  );

  const updateSinkMutation = useCallback(
    async (request: UpdateSinkMutationRequest, oldName: string) =>
      await handleComponentMutations(async () => await mutateUpdateSink(request), {
        nodes: { [oldName]: { sink: request.sink } },
      }),
    [mutateUpdateSink, handleComponentMutations],
  );

  const addSinkMutation = useCallback(
    async (request: AddSinkMutationRequest) =>
      await handleComponentMutations(async () => await mutateAddSink(request), {
        nodes: { [request.sink.name]: { sink: request.sink } },
      }),
    [mutateAddSink, handleComponentMutations],
  );

  const isDeleteSinkMutating = isDeleteSinkLoading;

  return { deleteSinkMutation, isDeleteSinkMutating, updateSinkMutation, addSinkMutation };
}
