import { PropsWithChildren, useState } from 'react';
import { ErrorReason } from '@common-types/common/errors/error_reasons';
import { QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import get from 'lodash/get';
import once from 'lodash/once';
import UnauthorizedPage from 'src/components/Error/UnauthorizedPage';
import { useNotification } from 'src/components/Notification/NotificationProvider';
import { RESPONSE_ERROR_REASON_PATH, RTK_ERROR_EVENT_NAME } from 'src/constants/errors';
import { REACT_QUERY_DEFAULT_STALE_TIME } from 'src/constants/queries';
import { useLogout } from 'src/hooks/useLogout';
import useOnMountEffect from 'src/hooks/useOnMountEffect';
import { getApiErrorMessage } from 'src/utils/notifications';

const WHITELISTED_ERROR_REASONS = [
  ErrorReason.INTERNAL_SERVER_ERROR,
  ErrorReason.REQUEST_TIMEOUT,
  ErrorReason.GATEWAY_TIMEOUT,
  ErrorReason.NO_DATA,
  ErrorReason.FORBIDDEN,
];

const getQueryClient = ({ onError }: { onError: (error: unknown) => void }) =>
  new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: REACT_QUERY_DEFAULT_STALE_TIME,
        retry: 2,
        refetchOnWindowFocus: false,
        retryOnMount: false,
      },
      mutations: {
        // Network mode is set to 'always' to prevent mutations from being stuck at paused (something that's been seemingly happening)
        networkMode: 'always',
        useErrorBoundary: (error) => {
          const errorReason = get(error, RESPONSE_ERROR_REASON_PATH);
          return errorReason === ErrorReason.FORBIDDEN;
        },
        onError,
      },
    },
    queryCache: new QueryCache({ onError }),
  });

const initReactQueryClient = once(getQueryClient);
const REDIRECT_LOGIN_PAGE_TIMEOUT = 10000;
type UnauthorizedError = ErrorReason.UNAUTHORIZED | ErrorReason.FORBIDDEN | undefined;

export const ReactQueryClientProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [queryClient, setQueryClient] = useState<QueryClient>();
  const [unauthorizedError, setUnauthorizedError] = useState<UnauthorizedError>();

  const { showErrorNotification } = useNotification();
  const onLogout = useLogout();

  useOnMountEffect(() => {
    const onError = (error: unknown) => {
      const errorReason = get(error, RESPONSE_ERROR_REASON_PATH);

      if (!errorReason || WHITELISTED_ERROR_REASONS.includes(errorReason)) {
        return;
      }

      if (errorReason === ErrorReason.UNAUTHORIZED) {
        setUnauthorizedError(errorReason);
        setTimeout(() => {
          onLogout();
        }, REDIRECT_LOGIN_PAGE_TIMEOUT);

        return;
      }

      showErrorNotification(getApiErrorMessage(error));
    };

    setQueryClient(initReactQueryClient({ onError }));

    const callback = ((event: CustomEvent) => {
      if (event.detail.error) {
        onError(event.detail.error);
      }
    }) as EventListener;

    document.addEventListener(RTK_ERROR_EVENT_NAME, callback);
    return () => document.removeEventListener(RTK_ERROR_EVENT_NAME, callback);
  });

  return queryClient ? (
    <QueryClientProvider client={queryClient}>
      {unauthorizedError ? <UnauthorizedPage error={unauthorizedError} /> : children}
    </QueryClientProvider>
  ) : null;
};
