import reduce from 'lodash/reduce';
import { PipelineGraphMap } from 'src/types/Pipeline';

export const updatePipelineGraphWithNewTransformIds = (
  pipelineGraphMap: PipelineGraphMap,
  transformMapping: RecordString = {},
) =>
  reduce(
    pipelineGraphMap,
    (acc, value, key) => {
      const newKey: string = transformMapping[key] ?? key;
      const newValue: string[] = [];

      value.nodeIds.forEach((val) => {
        newValue.push(transformMapping[val] ?? val);
      });

      return {
        ...acc,
        [newKey]: { nodeIds: newValue },
      };
    },
    {},
  );
