import { lazy, Suspense } from 'react';

const SitePage = lazy(() => import(/* webpackPrefetch: true */ './SitePage'));

const SiteDetailsPage = () => (
  <Suspense>
    <SitePage />
  </Suspense>
);

export default SiteDetailsPage;
