import {
  DisableArchivalForSourceRequest,
  DisableArchivalForSourceResponse,
} from '@common-types/site_manager/api/v1/site_api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'src/lib/axios/axios';

export const useDisableArchivalForSourceMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<
    { data: DisableArchivalForSourceResponse },
    unknown,
    DisableArchivalForSourceRequest
  >({
    mutationFn: (data) => axios.post('/v1/source/disable-archival', data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['sources'],
      });
    },
  });
};
