import { useCallback } from 'react';
import { Select, SingleValue } from 'chakra-react-select';
import { COLORS } from 'src/constants/colors';
import { doesStringsMatch } from 'src/utils/filter';
import { SearchSelectItem } from './types';

type SearchSelectInputProps = {
  placeholder?: string;
  selectedOption: SearchSelectItem | null;
  options?: SearchSelectItem[];
  handleSelect: (option: SingleValue<SearchSelectItem>) => void;
};

const components = {
  // to remove the chevron icon so that it looks similar to search box
  DropdownIndicator: () => null,
};

export const SearchSelectInput = ({
  placeholder = 'Search Here',
  selectedOption,
  options,
  handleSelect,
}: SearchSelectInputProps) => {
  const filterOption = useCallback(
    (option: SearchSelectItem, inputValue: string) =>
      inputValue ? doesStringsMatch(option.label, inputValue) : false,
    [],
  );

  return (
    <Select
      isClearable
      useBasicStyles
      placeholder={placeholder}
      noOptionsMessage={({ inputValue }) =>
        inputValue ? 'No options match the input query' : 'Type something to get suggestions'
      }
      components={components}
      onChange={handleSelect}
      value={selectedOption}
      options={options}
      filterOption={filterOption}
      chakraStyles={{
        control: (provided) => ({
          ...provided,
          borderRadius: '5px',
          minHeight: 10,
          _hover: {
            cursor: 'text',
          },
        }),
        option: (provided, { isSelected }) => ({
          ...provided,
          ...(isSelected && {
            backgroundColor: COLORS.disabledBlue,
            color: COLORS.disabled,
          }),
        }),
      }}
    />
  );
};
