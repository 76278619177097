import { ProcessorType } from '@common-types/site_manager/model/v1/node';
import { Property } from 'src/types/FormConfig';

export const TRANSFORMATIONS_ADDITIONAL_REQUIRED_FIELDS: Property[] = [
  {
    label: 'Name',
    name: 'name',
    placeholder: 'Name',
    type: 'string',
  },
  {
    label: 'Description',
    name: 'description',
    placeholder: 'Description',
    type: 'string',
  },
];

export const TRANSFORMATIONS_ADDITIONAL_REQUIRED_FIELDS_NAMES = ['name'];

export enum NodeOrigin {
  PreProcessor = 'NODE_ORIGIN_PREPROCESSOR',
  PostProcessor = 'NODE_ORIGIN_POSTPROCESSOR',
}

export const ALL_TRANSFORM_PROCESSOR_TYPES = [
  ProcessorType.DATA_PROCESSOR,
  ProcessorType.GENERIC_PREPROCESSOR,
  ProcessorType.CUSTOM_PREPROCESSOR,
  ProcessorType.GENERIC_POSTPROCESSOR,
  ProcessorType.CUSTOM_POSTPROCESSOR,
];
