import { AddSourceResponse } from '@common-types/site_manager/api/v1/site_api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNotification } from 'src/components/Notification/NotificationProvider';
import { NotificationStatus } from 'src/components/Notification/types';
import axios from 'src/lib/axios/axios';
import { AddSourceMutationRequest } from 'src/types/Source';

export function useAddSourceMutation() {
  const queryClient = useQueryClient();
  const { showNotification } = useNotification();

  return useMutation<{ data: AddSourceResponse }, unknown, AddSourceMutationRequest>({
    mutationFn: (data) => axios.post('/v1/source', data),
    onSuccess: async () => {
      showNotification(
        {
          message: 'Source added successfully.',
          status: NotificationStatus.Success,
        },
        {
          shouldTimeout: true,
        },
      );
      await queryClient.invalidateQueries({ queryKey: ['sources'] });
      await queryClient.invalidateQueries({ queryKey: ['list-sources'] });
      await queryClient.invalidateQueries({ queryKey: ['node-names'] });
    },
  });
}
