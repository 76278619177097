/* eslint-disable */

export const protobufPackage = "v1.pipeline_manager";

export enum PipelineAction {
  /** NOP - No operation. */
  NOP = "NOP",
  /** DEPLOY - Deploy the pipeline. */
  DEPLOY = "DEPLOY",
  /** DELETE - Delete the pipeline. */
  DELETE = "DELETE",
  /** PAUSE - Pause the pipeline. */
  PAUSE = "PAUSE",
  /** BYPASS - Bypass the pipeline. */
  BYPASS = "BYPASS",
  /** CANCEL - Cancel the pending action. */
  CANCEL = "CANCEL",
}

export enum PipelineType {
  PIPELINE_TYPE_UNSPECIFIED = "PIPELINE_TYPE_UNSPECIFIED",
  PIPELINE_TYPE_PATTERN = "PIPELINE_TYPE_PATTERN",
  PIPELINE_TYPE_ARCHIVAL = "PIPELINE_TYPE_ARCHIVAL",
  PIPELINE_TYPE_USER = "PIPELINE_TYPE_USER",
  PIPELINE_TYPE_SSA = "PIPELINE_TYPE_SSA",
}

/** Next ID: 14 */
export interface Pipeline {
  id: string;
  siteId: string;
  name: string;
  description: string;
  /** current status of the pipeline */
  status: Pipeline_PipelineStatus;
  createdAt: string | undefined;
  updatedAt: string | undefined;
  deletedAt: string | undefined;
  createdBy: string;
  updatedBy: string;
  deployedGraphVersion: number;
  pendingGraphVersion: number;
  latestGraphVersion: number;
  /**
   * This field is no longer used.
   * Please use GetPipelineNodeIds API to get the source id.
   *
   * @deprecated
   */
  sourceId: string;
  /** eg. Pattern, archival, user */
  pipelineType: PipelineType;
  /** action which the pipeline wants to be do. If pipeline is not in */
  pendingAction: PipelineAction;
}

export enum Pipeline_PipelineStatus {
  DEFAULT = "DEFAULT",
  /**
   * TO_DEPLOY - When the message is sent on event bus, status set to to_deploy.
   *
   * @deprecated
   */
  TO_DEPLOY = "TO_DEPLOY",
  /**
   * DEPLOYING - When control-agent starts processing, set to deploying.
   *
   * @deprecated
   */
  DEPLOYING = "DEPLOYING",
  /** DEPLOYED - control agent finished deploying and is successful. */
  DEPLOYED = "DEPLOYED",
  /** ROLLED_BACK - control agent failed deploying and rolled back to */
  ROLLED_BACK = "ROLLED_BACK",
  /** FAILED - previous version. */
  FAILED = "FAILED",
  /** DELETED - When the pipeline is deleted and no longer active. */
  DELETED = "DELETED",
  /**
   * DELETING - When the pipeline is being deleted.
   *
   * @deprecated
   */
  DELETING = "DELETING",
  /**
   * TO_DELETE - When the pipeline is in queue to be deleted.
   *
   * @deprecated
   */
  TO_DELETE = "TO_DELETE",
  /** PAUSED - When the pipeline is paused/disabled. */
  PAUSED = "PAUSED",
  /** BYPASSED - When the pipeline is bypassed. */
  BYPASSED = "BYPASSED",
}

export interface PipelineGraph {
  version: number;
  pipelineId: string;
  edges: { [key: string]: NodeIds };
  created: string | undefined;
  createdBy: string;
  /**
   * key: source/sink id, value: internal node id.
   * This is populated only when the internal_node filter is set to true.
   * The edges will not have any internal node ids when this is populated.
   */
  internalNodes: { [key: string]: string };
}

export interface PipelineGraph_EdgesEntry {
  key: string;
  value: NodeIds | undefined;
}

export interface PipelineGraph_InternalNodesEntry {
  key: string;
  value: string;
}

export interface NodeIds {
  nodeIds: string[];
}

export interface TransformComponents {
  root: string;
  leaf: string;
  internal: string[];
}

export interface DeployedNodes {
  sourceNodeIds: string[];
  sinkNodeIds: string[];
  transformNodeIds: string[];
}

export interface NeighborComponents {
  value: string[];
}

export interface PipelineComponents {
  sourceChildren: { [key: string]: NeighborComponents };
  sinkParents: { [key: string]: NeighborComponents };
  transforms: { [key: string]: TransformComponents };
}

export interface PipelineComponents_SourceChildrenEntry {
  key: string;
  value: NeighborComponents | undefined;
}

export interface PipelineComponents_SinkParentsEntry {
  key: string;
  value: NeighborComponents | undefined;
}

export interface PipelineComponents_TransformsEntry {
  key: string;
  value: TransformComponents | undefined;
}
