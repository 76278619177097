import { Sink, Source, Transform } from '@common-types/site_manager/model/v1/node';
import startCase from 'lodash/startCase';
import { createFilterOption } from 'src/components/Filters/utils';

export const getNodeFilterValues = <T extends Source | Sink | Transform>(nodes?: T[]) =>
  nodes?.map(({ id, name }) => createFilterOption(id, name)) ?? [];

export function doesStringsMatch(str1: string, str2: string): boolean {
  return startCase(str1)
    .toLowerCase()
    .replace(/ /g, '')
    .includes(startCase(str2).toLowerCase().replace(/ /g, ''));
}
