import { Site, Site_Status } from '@common-types/site_manager/model/v1/site';
import { SITE_NEVER_USED } from 'src/features/SiteForm/constants';

export const isSite = (site?: Site): site is Site => Boolean(site && site.id);

export const isSiteEverUsed = (site?: Site): site is Site & boolean =>
  isSite(site) && site.lastAlive !== SITE_NEVER_USED;

export const isSiteActiveAndAlive = (site?: Site): site is Site & boolean =>
  isSiteEverUsed(site) && site.status === Site_Status.ACTIVE;
