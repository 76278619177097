import { createSlice } from '@reduxjs/toolkit';
import { isBoolean } from 'src/utils/dataTypeDetection';

const DEFAULT_RESOLVE_FN: (comment?: string) => Promise<void> = () => Promise.resolve();

const raisePrSlice = createSlice({
  name: 'raisePr',
  reducerPath: 'raisePr',
  initialState: {
    showModal: false,
    resolveFn: DEFAULT_RESOLVE_FN,
  },
  reducers: {
    setRaisePr: (state, action) => {
      const values = { ...state, ...action.payload };
      if (isBoolean(values.showModal)) {
        state.showModal = values.showModal;
      }
      if (values.showModal && values.resolveFn) {
        state.resolveFn = values.resolveFn;
      } else {
        state.resolveFn = DEFAULT_RESOLVE_FN;
      }
    },
  },
  selectors: {
    selectPrModalShow: (state) => state.showModal,
    selectResolveFn: (state) => state.resolveFn,
  },
});

export default raisePrSlice;
