import { PipelineAction } from '@common-types/pipeline_manager/model/v1/pipeline';
import { useCallback } from 'react';
import { usePipelineActionMutation } from '../api/usePipelineActionMutation';

export function usePipelineAction() {
  const { mutateAsync: pipelineActionMutation, isLoading: isPipelineActionMutating } =
    usePipelineActionMutation();

  const onPipelineDeploy = useCallback(
    async (pipelineId?: string, graphVersion?: number) => {
      if (!graphVersion || !pipelineId) {
        throw new Error('Invalid Graph version received or invalid pipeline id');
      }

      await pipelineActionMutation({
        pipelineId,
        graphVersion,
        action: PipelineAction.DEPLOY,
      });
    },
    [pipelineActionMutation],
  );

  const onMutatePipelineAction = useCallback(
    async (pipelineId: string, graphVersion: number, action: PipelineAction) =>
      await pipelineActionMutation({ pipelineId, graphVersion, action }),
    [pipelineActionMutation],
  );

  return { onPipelineDeploy, onMutatePipelineAction, isPipelineActionMutating };
}
