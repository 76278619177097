import { PipelineGraph } from '@common-types/pipeline_manager/model/v1/pipeline';
import { ReplacePipelineGraphResponse } from '@common-types/pipeline_manager/api/v1/pipeline_api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'src/lib/axios/axios';

type ReplacePipelineGraphMutationRequest = Pick<
  PipelineGraph,
  'pipelineId' | 'edges' | 'internalNodes'
>;

export const useReplacePipelineGraphMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<
    { data: ReplacePipelineGraphResponse },
    unknown,
    ReplacePipelineGraphMutationRequest
  >({
    mutationFn: (data) => axios.put('/v1/pipeline-graph', data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['pipelineGraph'] });
      queryClient.invalidateQueries({ queryKey: ['pipelines'] });
    },
  });
};
