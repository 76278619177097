import {
  ListSitesResponse,
  AddSiteResponse,
  UpdateSiteResponse,
} from '@common-types/site_manager/api/v1/site_api';
import { PaginationRequestParams, PaginationSortParams } from 'src/types/PaginationRequest';
import apiSlice from 'src/store/api';
import { DEFAULT_PAGINATION_ARGS } from 'src/constants/queries';
import { SiteFormValues } from 'src/features/SiteForm/types';

export const API_TAG_SITES = 'sites';

const apiWithTag = apiSlice.enhanceEndpoints({ addTagTypes: [API_TAG_SITES] });

interface GetSiteListQueryParams {
  siteIds?: string[];
  pagination?: PaginationRequestParams | undefined;
  paginationSort?: PaginationSortParams;
}

interface UpdateSiteRequest {
  site: Partial<SiteFormValues> & { id: string };
  lastUpdated?: string;
}

const injectedEndpoints = apiWithTag.injectEndpoints({
  endpoints: (build) => ({
    listSites: build.query<ListSitesResponse, GetSiteListQueryParams>({
      query: ({ siteIds = [], pagination = DEFAULT_PAGINATION_ARGS, paginationSort }) => ({
        url: '/v1/sites',
        params: {
          siteIds,
          ...pagination,
          'pagination.ordering.fieldName': 'updated_at',
          'pagination.ordering.descending': true,
          ...paginationSort,
        },
      }),
      providesTags: [API_TAG_SITES],
    }),

    addSite: build.mutation<AddSiteResponse, SiteFormValues>({
      query: (data) => ({
        url: '/v1/site',
        method: 'POST',
        body: { site: data },
      }),
      invalidatesTags: [API_TAG_SITES],
    }),

    updateSite: build.mutation<UpdateSiteResponse, UpdateSiteRequest>({
      query: (data) => ({
        url: '/v1/site',
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: [API_TAG_SITES],
    }),
  }),
  overrideExisting: false,
});

export const {
  useListSitesQuery,
  useLazyListSitesQuery,
  useAddSiteMutation,
  useUpdateSiteMutation,
} = injectedEndpoints;
