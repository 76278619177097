export const isBoolean = (data: unknown): data is boolean =>
  typeof data === 'boolean' || data instanceof Boolean;

export const isNumber = (data: unknown): data is number =>
  typeof data === 'number' || data instanceof Number;

export const isBigInt = (data: unknown) => typeof data === 'bigint' || data instanceof BigInt;

export const isDate = (data: unknown): data is Date => !!data && data instanceof Date;

export const isString = (data: unknown): data is string =>
  typeof data === 'string' || data instanceof String;

export const isArray = (data: unknown): data is [] => Array.isArray(data);

export const isObject = (data: unknown) => data instanceof Object && data !== null;

export const isNull = (data: unknown) => data === null;

export const isUndefined = (data: unknown) => data === undefined;

export function isNotNumber(data: unknown): boolean {
  const strVal = String(data);

  return isArray(data) || strVal.trim() === '' || isNaN(Number(strVal));
}
