import { useCallback } from 'react';
import { usePipelineGraphContext } from 'src/context/PipelineGraphProvider';
import { useSite } from 'src/context/SiteProvider';
import { PipelineType } from '@common-types/pipeline_manager/model/v1/pipeline';
import { CreateChangeSetRequest } from './types';
import { isNonEmptyArray } from 'src/utils/isNonEmptyArray';
import { getTransformRequestPayload } from './utils/transform';
import { Source } from 'src/types/Source';

export function usePipelineCreatePrRequestParams() {
  const { siteId } = useSite();

  const {
    pipelineName,
    pipelineDescription,
    pipelineComponents: {
      transformationItems,
      source,
      destinations,
      pipelineDisplayedGraphVersion,
    },
    pipelineGraphMap,
    pipelineGraphInternalNodes,
  } = usePipelineGraphContext();

  const getPipelinePrRequestParams = useCallback(
    (pipelineId = '', name?: string, description?: string) => {
      let isIncludeGraph = true;
      let isIncludePipeline = true;
      if (pipelineId && !name) {
        isIncludePipeline = false;
      } else if (pipelineId && name) {
        isIncludeGraph = false;
      }

      const requestObject: Pick<CreateChangeSetRequest, 'nodes' | 'pipelines'> = {
        pipelines: {
          [pipelineName]: {
            pipeline: isIncludePipeline
              ? {
                  id: pipelineId,
                  siteId,
                  name: name || pipelineName,
                  description: description || pipelineDescription,
                  pipelineType: PipelineType.PIPELINE_TYPE_USER,
                }
              : undefined,
            pipelineGraph: isIncludeGraph
              ? {
                  pipelineId,
                  edges: pipelineGraphMap,
                  internalNodes: pipelineGraphInternalNodes,
                  version: pipelineDisplayedGraphVersion,
                }
              : undefined,
          },
        },
      };
      return requestObject;
    },
    [
      pipelineDescription,
      pipelineDisplayedGraphVersion,
      pipelineGraphInternalNodes,
      pipelineGraphMap,
      pipelineName,
      siteId,
    ],
  );

  const getCreatePipelinePrRequestParams = useCallback(() => {
    const requestObject = getPipelinePrRequestParams();
    const nodes: CreateChangeSetRequest['nodes'] = {};
    const transforms = getTransformRequestPayload(transformationItems, '', siteId);
    if (isNonEmptyArray(transforms)) {
      transforms.forEach((transform) => {
        nodes[transform.name] = { transform };
      });
    }
    if (isNonEmptyArray(destinations)) {
      destinations.forEach((sink) => {
        nodes[sink.name] = { sink };
      });
    }
    if (source) {
      nodes[source.name] = { source: source as Source };
    }
    requestObject.nodes = nodes;
    return requestObject;
  }, [getPipelinePrRequestParams, transformationItems, siteId, destinations, source]);

  const getUpdatePipelinePrRequestParams = useCallback(
    (pipelineId: string) => {
      const requestObject = getPipelinePrRequestParams(pipelineId);
      const transforms = getTransformRequestPayload(transformationItems, '', siteId);
      if (isNonEmptyArray(transforms)) {
        const nodes: CreateChangeSetRequest['nodes'] = {};
        transforms.forEach((transform) => {
          nodes[transform.name] = { transform };
        });
        requestObject.nodes = nodes;
      }
      return requestObject;
    },
    [getPipelinePrRequestParams, siteId, transformationItems],
  );

  return {
    getCreatePipelinePrRequestParams,
    getPipelinePrRequestParams,
    getUpdatePipelinePrRequestParams,
  };
}
