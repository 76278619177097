import { combineReducers, configureStore } from '@reduxjs/toolkit';
import raisePrSlice from './raisePr';
import apiSlice from './api';

const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  [raisePrSlice.reducerPath]: raisePrSlice.reducer,
});

export default configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(apiSlice.middleware),
});
