import {
  PipelineActionRequest,
  PipelineActionResponse,
} from '@common-types/pipeline_manager/api/v1/pipeline_api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'src/lib/axios/axios';

export const usePipelineActionMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<{ data: PipelineActionResponse }, unknown, PipelineActionRequest>({
    mutationFn: ({ pipelineId, graphVersion, action }) =>
      axios.patch('/v1/pipeline/action', { pipelineId, graphVersion, action }),

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['pipelines'] });
    },
  });
};
